export enum ROUTE_PATHS {
  // Admin route paths should all start with this as the prefix.
  // Enums sadly do not allow dynamic values, otherwise would use shared base variable
  ADMIN_BASE = '/touringery',
  TOURINGERY_PROVIDER_LIST = `/touringery/companies`,
  TOURINGERY_DEPOSIT_REPORT = `/touringery/reports/deposit`,
  TOURINGERY_COMMISSION_REPORT = `/touringery/reports/commission`,
}

export default { ROUTE_PATHS }
