import ProviderLayout from '@/layouts/ProviderLayout.vue'
import { ROUTE_PATHS as ADMIN_ROUTE_PATHS } from '@/router/admin/paths'
import { ROUTE_PATHS } from '@/router/paths'
import { useAuthStore } from '@/store/auth'
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

const adminRoutes: Array<RouteRecordRaw> = [
  {
    path: ADMIN_ROUTE_PATHS.ADMIN_BASE,
    name: ADMIN_ROUTE_PATHS.ADMIN_BASE,
    redirect: ADMIN_ROUTE_PATHS.TOURINGERY_PROVIDER_LIST,
    component: () => import('@/views/admin/AdminWrapperView.vue'),
    beforeEnter: [isAdminUser],
    children: [
      {
        path: ADMIN_ROUTE_PATHS.TOURINGERY_PROVIDER_LIST,
        name: ADMIN_ROUTE_PATHS.TOURINGERY_PROVIDER_LIST,
        component: () => import('@/views/admin/AdminCompanyListView.vue'),
        meta: { layout: ProviderLayout },
      },
      {
        path: ADMIN_ROUTE_PATHS.TOURINGERY_DEPOSIT_REPORT,
        name: ADMIN_ROUTE_PATHS.TOURINGERY_DEPOSIT_REPORT,
        component: () =>
          import('@/views/admin/TouringeryDepositReportView.vue'),
        meta: { layout: ProviderLayout },
      },
      {
        path: ADMIN_ROUTE_PATHS.TOURINGERY_COMMISSION_REPORT,
        name: ADMIN_ROUTE_PATHS.TOURINGERY_COMMISSION_REPORT,
        component: () =>
          import('@/views/admin/TouringeryCommissionReportView.vue'),
        meta: { layout: ProviderLayout },
      },
    ],
  },
]

function isAdminUser(to: RouteLocationNormalized) {
  const authStore = useAuthStore()
  // If not even logged in we redirect to login view.
  if (!authStore.isAuthenticated) {
    return {
      path: ROUTE_PATHS.LOGIN,
      query: {
        next: to.path,
      },
    }
  }
  // If logged in we redirect to root, it should redirect where necessary based on state in main routes definition.
  if (!authStore.isAdminUser) {
    return {
      path: '/',
    }
  }
}

export default adminRoutes
